import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { forgotPassword, forgotPasswordFailure, forgotPasswordSuccess } from '@features/forgot-password/actions/forgot-password.actions';
import { LoginApiService } from '@core/services/login-api.service';
import { Router } from '@angular/router';
import { ToastService } from '@shared/services/toast.service';

@Injectable()
export class ForgotPasswordEffects {
  constructor(private actions$: Actions, private loginApiService: LoginApiService, private router: Router, private toastService: ToastService) {}

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword),
      switchMap((action) => {
        return this.loginApiService.forgotPassword(action.user).pipe(
          map(() => {
            return forgotPasswordSuccess({ user: action.user, isRetry: action.isRetry });
          }),
          catchError((error) => {
            return of(forgotPasswordFailure({ error }));
          })
        );
      })
    )
  );

  forgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forgotPasswordSuccess),
        tap((action) => {
          action.isRetry ? this.toastService.showToast('success', 'Email has been resent successfully') : this.router.navigate(['forgot-password', 'success']);
        })
      ),
    { dispatch: false }
  );

  forgotPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forgotPasswordFailure),
        tap((action) => {
          const message = action.error.error.errors;
          this.toastService.showToast('error', message);
        })
      ),
    { dispatch: false }
  );
}
