export const environment = {
  production: true,
  environment: 'production',
  company_name: 'ESET Southern Africa',
  auth_token:
    'eyJhbGciOiJIUzUxMiJ9.eyJhcHBsaWNhdGlvbiI6ImxvZ2luIiwidG9rZW4iOiI2YmRjNWUzYmI1YmVhYjJjNWE4ODMwZGM5In0.pG-3Z_qQxK5GfEfmAT38Wlmn5Pxr-yBWXK8go19Z9Iij4HQrqxNoaFuG1djcmP796YZ43iQS3vYwgXoLsw0fuw',
  api_url: 'https://authenticate.eset.co.za',
  transact_url: 'https://transact.eset.co.za',
  cookie_scope: '',
  namespace: '.eset.co.za',
  eset_support_email: 'support@eset.co.za',
  eset_partner_email: 'partner@eset.co.za',
  eset_website: 'https://www.eset.com/za',
  passphrase_regex: /((\w){1,}(\ |\-|\,|\.|\_)(\w){1,}){2,}/i,
  uppercase_regex: /[A-Z]{1}/,
  numeric_regex: /\d{1}/,
  xkcd_regex: /(correcthorse|correct\ horse)/i,
  password_min_length: 8,
  passphrase_min_length: 16,
  min_zxcvbn_score: 3,
  static_domain: 'https://static.eset.co.za/',
};
