import { createSelector, createReducer, on, Action } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import { login, loginFailed, loginSuccess } from '../actions/login.actions';

export const featureKey = 'login';
export interface State {
  isLoading: boolean;
  accessToken: string;
  mfaActive: boolean;
  acceptedPartnerAgreement?: boolean;
}

export const initialState: State = {
  isLoading: false,
  accessToken: '',
  mfaActive: false,
};

export const loginReducer = createReducer(
  initialState,
  on(login, (state) => {
    return { ...state, isLoading: true };
  }),
  on(loginFailed, (state, action) => {
    return { ...state, isLoading: false };
  }),
  on(loginSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      accessToken: action.data.access_token,
      mfaActive: action.data.mfa_active,
      acceptedPartnerAgreement: action.data.accepted_partner_agreement,
    };
  })
);

export function reducer(state: State, action: Action) {
  return loginReducer(state, action);
}

export const loginFeatureSelector = createFeatureSelector<State>(featureKey);

export const selectLoginLoading = createSelector(loginFeatureSelector, (state) => state.isLoading);

export const selectAccessToken = createSelector(loginFeatureSelector, (state) => state.accessToken);

export const selectMfaActive = createSelector(loginFeatureSelector, (state) => state.mfaActive);

export const selectAcceptedPartnerAgreement = createSelector(loginFeatureSelector, (state) => state.acceptedPartnerAgreement);
