import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { LoginApiService } from '@core/services/login-api.service';
import { login, loginFailed, loginSuccess } from '../actions/login.actions';
import { Router } from '@angular/router';
import { LoginService } from '@features/login/services/login.service';
import { ToastService } from '@shared/services/toast.service';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginApiService: LoginApiService,
    private router: Router,
    private loginService: LoginService,
    private toastService: ToastService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action) => {
        return this.loginApiService.login(action.user).pipe(
          map((response) => {
            return loginSuccess({ data: response });
          }),
          catchError((error) => {
            return of(loginFailed({ error }));
          })
        );
      })
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap((action) => {
          if (action.data.mfa_active) {
            this.router.navigate(['mfa-otp']);
          }
          if (!action.data.accepted_partner_agreement && action.data.hasOwnProperty('accepted_partner_agreement')) {
            this.router.navigate(['partner-agreement']);
          }
          if (!action.data.mfa_active && (!!action.data.accepted_partner_agreement || !action.data.hasOwnProperty('accepted_partner_agreement'))) {
            this.loginService.startSession({});
          }
        })
      ),
    { dispatch: false }
  );

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailed),
        tap((action) => {
          let message = action.error.error.errors;
          if (action.error.error.hasOwnProperty('attempts_remaining')) {
            message = message + `<br> You have ${action.error.error.attempts_remaining} attempts remaining`;
          }
          this.toastService.showToast('error', message, { duration: 0 });
        })
      ),
    { dispatch: false }
  );
}
