import { Component, OnInit, SecurityContext } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { RouterService } from '@shared/services/router.service';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInFadeOut', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  routeData$: Observable<any>;
  title$: Observable<string>;
  subtitle: SafeHtml | null = null;

  constructor(private routerService: RouterService, private titleService: Title, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.routeData$ = this.routerService.getRouteData();
    this.routeData$.subscribe((data) => {
      if (!!data) {
        this.titleService.setTitle(data.title + ' - ESET');
        this.subtitle = data.subtitle ? this.sanitizer.sanitize(SecurityContext.HTML, data.subtitle) : null;
      }
    });
  }
}
