import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('@features/forgot-password/forgot-password.module').then((m) => m.forgotPasswordModule),
  },
  {
    path: 'set-password',
    loadChildren: () => import('@features/set-password/set-password.module').then((m) => m.SetPasswordModule),
  },
  {
    path: 'contact-confirmation',
    loadChildren: () => import('@features/confirm-email/confirm-email.module').then((m) => m.ConfirmEmailModule),
  },
  {
    path: 'recover-mfa',
    loadChildren: () => import('@features/recover-mfa/recover-mfa.module').then((m) => m.RecoverMfaModule),
  },
  {
    path: 'activate-account',
    loadChildren: () => import('@features/set-password/set-password.module').then((m) => m.SetPasswordModule),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
