<form [formGroup]="loginForm" class="relative overflow-visible pt-2 w-[345px]">
  <div class="flex flex-row h-[215px]">
    <div [@slideInOut]="'active'" class="w-[345px] absolute">
      <div class="flex-row py-1 mb-2 relative">
        <label class="block py-2 text-grey-blue">Email Address</label>
        <input
          #email
          autocomplete="username"
          id="email"
          type="text"
          formControlName="email"
          (focus)="loginForm.controls['email'].markAsUntouched()"
          autofocus
          [ngClass]="{ 'border-bright-crimson text-bright-crimson': loginForm.controls['email'].errors && loginForm.controls['email'].touched }"
        />
        <div
          class="text-bright-crimson text-right text-sm py-2 right-0 absolute"
          [hidden]="!(loginForm.controls['email'].errors && loginForm.controls['email'].touched)"
        >
          <div id="required" class="error" *ngIf="loginForm.controls['email'].errors?.['required']">Email Address is required</div>
        </div>
      </div>
      <div class="flex-row py-1 mb-8 relative">
        <label class="block py-2 text-grey-blue">Password</label>
        <input
          autocomplete="current-password"
          id="password"
          type="password"
          formControlName="password"
          (focus)="loginForm.controls['password'].markAsUntouched()"
          [ngClass]="{ 'border-bright-crimson text-bright-crimson': loginForm.controls['password'].errors && loginForm.controls['password'].touched }"
          (keyup.enter)="submitLogin()"
        />
        <div
          class="text-bright-crimson text-sm py-2 absolute right-0"
          [hidden]="!(loginForm.controls['password'].errors && loginForm.controls['password'].touched)"
        >
          <div id="required" class="error" *ngIf="loginForm.controls['password'].errors?.['required']">Password is required</div>
        </div>
      </div>
    </div>
  </div>
</form>
<button id="submit" (click)="submitLogin()" class="my-2 block w-full">Continue</button>
<a id="forgot-password" routerLink="/forgot-password" class="mt-4 block text-primary text-center font-medium w-full">Forgot Password? </a>
